<template>
    <div class="notices">
        <div class="legal-title">
            Mentions legales
        </div>

        <HCard
            free
            class="legal-block"
        >
            <div class="block-title">Editeur du Site :</div>
            <div class="block-text">
                <p>HDC Santé, société par actions simplifiée au capital de 13.198 €</p>
                <p>RCS de Paris n° 790 294 615</p>
                <p>N° TVA Intracommunautaire : FR 60 790 294 615</p>
                <p>Siège social : 59, rue Pernety, 75014 Paris, France</p>
                <p>Téléphone : <a href="tel:0184800829" target="_blank">01 84 80 08 29</a> (n° non surtaxé)</p>
                <p>E-mail : <a href="mailto:contact@happytal.com" target="_blank">contact@happytal.com</a></p>
            </div>
        </HCard>

        <HCard
            free
            class="legal-block"
        >
            <div class="block-title">Directeur de la publication :</div>
            <div class="block-text">
                <p>Romain REVELLAT</p>
            </div>
        </HCard>

        <HCard
            free
            class="legal-block"
        >
            <div class="block-title">Hébergement du site :</div>
            <div class="block-text">
                <p>Amazon Web Services EMEA</p>
                <p>Société de droit Luxembourgeois, RCS Luxembourg B186284</p>
                <p>Siège social : 38, avenue John F. Kennedy, L-1855 Luxembourg</p>
                <p>E-mail : <a href="mailto:aws-eu-privacy@amazon.com" target="_blank">aws-eu-privacy@amazon.com</a></p>
            </div>
        </HCard>

    </div>
</template>

<script>
    import {
        HCard
    } from '@happytal/bo-ui-library'

    export default {
        components: {
            HCard
        }
    }
</script>
